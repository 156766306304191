@import "styles/style.scss";

.loader {
    position: relative;
    border: 5px solid rgba(255, 255, 255, 0.4);
    height: 120px;
    width: 120px;
    border-radius: 100%;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: inherit;
        border-radius: inherit;
    }

    &::before {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
    }

    .progressRing {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .circle,
        .circleBg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // axis compensation
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
        }

        .circle {
            transition: stroke-dashoffset 0.35s;
        }

    }
    
    .timer {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        color: $white;
        transform: translate(-50%, -50%);

        font-size: 3rem;
    }
}
