.portrait {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0 auto;
      padding: .25em;
      aspect-ratio: 343/442;
    }
}