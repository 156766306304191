.sk-phone-input {
    
    &__home {
        @include numberInput;
        height: 53px;

        & .PhoneInput {
            & > input {
                font-size: 1rem;
                color: white;

                &::placeholder {
                    color: white;
                }
                
                background: transparent;
            }
        }
    }

    &__event {
        @include numberInput;
        background-color: transparent;
        border: 1px solid $grey;
        color: $grey;

        > input {
            border-color: $grey;
            color: $grey;

            &::placeholder {
                color: $grey;
            }
        }
        > svg {
            fill: $grey;
        }
    }
}
