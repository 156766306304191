.snackbar {
    max-width: 185px;

    [class*="MuiPaper-root"][class*="MuiSnackbarContent-root"] {
        padding: 0;
        overflow: hidden;

        [class="MuiSnackbarContent-message"] {
            padding: 0;
            height: 100%;

            > div {
                display: flex;
            }
        }
    }
}

.close {
    position: absolute;
    top: 0px;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    border-top-right-radius: 10px;
    padding-right: 4px;
    padding-top: 4px;
    z-index: 1;
    overflow: hidden;

    &:before {
        content: "";
        height: calc(100% + 40px);
        width: calc(100% + 40px);        
        position: absolute;
        top: -56px;
        right: -48px;
        transform: rotate(45deg);
        background: rgba(255,255,255,.8);
        pointer-events: none;
    }
}
