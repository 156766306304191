@import "../../../styles/style.scss";

.home {
    position: relative;
    height: 100vh;
    padding: 5em 2em;
    color: $white;
    overflow: hidden;
    isolation: isolate;

    &::before {
        content: "";
        position: absolute;
        background-color: $primary;
        background-image: url("../images/main-bg.jpg");
        background-repeat: no-repeat;
        background-blend-mode: darken;
        background-size: cover;
        height: 100vh;
        background-position: center -25px;

        width: 100%;
        top: 0;
        left: 0;

        filter: blur(20px);
        transform: scale(1.2);
    }

    .logo {
        text-align: center;

        & > svg {
            font-size: 5rem;
            fill: $white;
            margin-bottom: 0.2em;
        }
    }

    &__title {
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 2em;
    }

    .text {
        & > span {
            text-transform: uppercase;
            font-weight: 800;
        }
    }

    a.browse {
        box-shadow: inset 0px 0px 28px #16004a;
        background-color: #5003fb;
        color: #fff;
    }

    .divider {
        position: relative;
        display: block;
        text-align: center;
        margin: 2em 0;
        text-transform: uppercase;

        &::before,
        &::after {
            content: "";
            background-color: $white;
            width: 40%;
            height: 2px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }
    }
}
