$primary: #4803e3;

.modal {
    [class*="MuiPaper-elevation24"] {
        box-shadow: 0px 0px 60px #3b17ad, 24px 24px 26px rgba(13, 39, 80, 0.02), inset -24px -24px 44px #ffffff,
            inset 44px 44px 44px rgba(13, 39, 80, 0.08);
    }

    .radio {
        > span[class*="MuiFormControlLabel-label"] {
            width: 100%;
        }
    }

    [class*="MuiRadio-colorSecondary"] {
        &:hover {
            background-color: rgba(15, 0, 255, 0.04) !important;
        }
    }

    [class*="MuiRadio-colorSecondary"][class*="Mui-checked"] {
        color: rgb(15, 0, 255);
        position: relative;

        &:hover {
            background-color: rgba(0, 150, 0, 0.04);
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }

        &:before {
            background: rgb(15, 0, 255);
            background: linear-gradient(135deg, #ff0033 0%, #0f00ff 100%);
            z-index: 1;
        }

        &:after {
            border: 3px solid #e1f2ff;
            width: 16px;
            height: 16px;
            z-index: 2;
        }
    }
}

.content {
    padding: 0 30px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 400px;
    background: #e1f2ff;

    + div {
        background: #e1f2ff;
    }

    .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 6px;
        transform: translate(12px, -8px);

        a {
            min-width: 36px;
            background: #edf1f4;
            border-radius: 50%;
            width: 36px !important;
            height: 36px !important;
            box-shadow: -6px -5px 12px #ffffff, 5px 5px 15px rgba(0, 0, 0, 0.18), inset -4px -4px 12px #ffffff,
                inset 4px 4px 10px rgba(13, 39, 80, 0.18) !important;
            font-size: 1.25rem;
        }
    }

    .image {
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 12px 12px 26px rgba(0, 0, 0, 0.18), -12px -12px 15px #ffffff;
        background-color: #6bb7ff;
    }

    .title {
        font-size: 1rem;
        line-height: 19px;
    }

    .description {
        margin-top: 8px;
        font-size: 1rem;
    }

    .poweredBy {
        text-align: center;
        margin-top: 1em;
        padding: 0.4em;
        border-radius: 3px;
        font-size: 0.875rem;
        color: $primary;
        font-weight: 500;

        strong {
            font-weight: 600;
        }
    }
}

.loadContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
}