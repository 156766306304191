// proximal nova font will need to be purchased
// incorrect font but similar for now
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #E1F2FF;
}

p,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

p {
  font-size: 1rem;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

input {
  border: none;
  outline: none;
}

// Webcam
.webcam {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: white;

  video {
    align-self: flex-start;
  }

  &__grid {
    position: absolute;
    transform: translateX(-50%);
    top: -2em;
    left: 50%;
    height: 100vh;
    width: 95%;
    opacity: 0.8;
    z-index: 2;
    max-width: 460px;
    pointer-events: none;

    &::after,
    &::before {
      content: "";
      width: 100%;
      background-color: $primary;
      position: absolute;
      top: -2em;
      height: 150vh;
    }

    &:after {
      right: 100%;
    }

    &:before {
      left: 100%;
    }
  }

  &__demo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transform: scale(1.5);

    & > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: blur(5px);
      height: 100%;
    }
  }

  // Used in Phase One will be removed after
  &__verify {
    position: absolute;
    background-color: $white;
    top: 50%;
    left: 50%;
    text-align: center;
    border-radius: 12px;
    min-height: 200px;
    z-index: 200;
    padding: 1em;
    transform: translate(-50%, -50%);

    & button {
      @include button;
      margin-top: 1em;
      width: 100%;
      border: none;
      color: $white;
      background-color: $primary;
    }
  }
}

@media only screen and (min-width: 475px) {
  .webcam {
    &__grid {
      width: 450px;
    }
  }
}

button,
input {
  font-family: "Montserrat", sans-serif;
}