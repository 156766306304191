.editor-slider {
  position: absolute;
  top: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5em;
    background-color: $black;
    border: 1px solid #828282;
    border-left: none;
    border-right: none;
  }
  
  // if no center on mobile add align-items: center;
  &__wrapper {
    display: flex;
    padding: 0 1em;
    width: 100%;

    &::before {
      content: '';
      // relative to .edit-photo__options-container
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 1px;
      height: 1.75em;
      background-color: #4C4C4C;
    }
  }

  &__input {
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    width: 100%;
    outline: none;
    z-index: 1;

    // doesn't seem to stack
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background: $white;
      cursor: pointer;
      height: 1.625em;
      width: 1.625em;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      background: $white;
      cursor: pointer;
      height: 1.625em;
      width: 1.625em;
      border-radius: 50%;
    }
  }

  &__details {
    display: flex;
    font-size: 0.875rem;
    padding: 0 1em;
    height: 4.5em;
    align-items: center;
    justify-content: space-between;
    background-color: $black;
    width: 100%;
    color: $white;
    text-transform: capitalize;
    
    & > svg {
      font-size: 1.4rem;
      cursor: pointer;
    }
  }
}