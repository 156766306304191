@import "./grid-animation.module.scss";
@import "./grid-variables";

.grid-hundred {
  margin: 0 auto;
  display: grid;
  // for visual purposes logic is in GridLarge component
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  height: 100%;
  background-color: $gridBackground;

  .image-container {
    overflow: hidden;
    width: 100%;
    border: $gridBorderWidth solid $gridBorderColor;
    border-radius: $imageRadius;
    height: 100%;

    .whiteBorder {
      border: 2px solid white;
      border-radius: 6px;
      overflow: hidden;
      height: 100%;
    }
  }

  .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
