.sk-phone-login {
    &__captcha {
        &.sk-btn-home {
            background-color: rgba(255, 255, 255, 0.1);
            border: 1px solid $white;
        }

        &__input {
            &.sk-input-light {
                .MuiInputBase-root.MuiOutlinedInput-root {
                    background-color: rgba(0, 0, 0, 0.3);
                    color: white;
                }
            }
        }
    }
}
