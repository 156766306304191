//--------------------
// Grid Setup
//--------------------
.grid-dynamic {
    display: grid;
    margin: auto;
    padding: 0;
    overflow: hidden;
    height: calc(var(--height-resize) - 300px);
    position: relative;
    min-width: 100vw;

    @media screen and (min-width: 768px) {
        height: calc(var(--height-resize) - 200px);
        min-width: 1600px;
        min-height: 650px;
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 16px solid map-get($colors, primary);
        z-index: 20;
    }


    div {
        height: 100%;
        width: 100%;
        display: block;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
            aspect-ratio: 0.78/1;
        }
    }
}
