.button {
    all: unset;
    cursor: pointer;
    border-radius: 50px;    
    background: #edf1f4;
    box-shadow:
        -6px -5px 12px #ffffff,
        5px 5px 15px rgba(0, 0, 0, 0.18),
        inset -4px -4px 12px #ffffff,
        inset 4px 4px 10px rgba(13, 39, 80, 0.18);
    display: inline-block;
    text-align: center;
    
    &.disabled {
        opacity: 0.5;
    }

    .text {
        display: inline-block;
        padding: 8px 16px;
        font-weight: 600;
        background-image: linear-gradient(to right, #6c63ff, #ff6584);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
