@import "styles/variables";

.container {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center;

    &.left {
        flex-direction: row-reverse;
    }
}

.gradientText {
    background-image: linear-gradient(135deg, #ff0033 0%, #0f00ff 100%);
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600 !important;
    color: transparent;
}

.svg {
    position: relative;

    .pullMargins {
        margin: -32px -39px -46px -32px;
    }

    .scale {
        $scaleSidePosition: -24px;

        position: absolute;
        top: 0;
        right: $scaleSidePosition;
        width: 30px;
        height: 96px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.left {
            align-items: flex-end;
            right: unset;
            left: $scaleSidePosition;

            .indicator {
                flex-direction: row-reverse;

                .tick {
                    background: linear-gradient(180deg, #0d8800 0%, #71db00 58.79%, #5ec908 100%);
                }

                .text {
                    text-align: right;
                }
            }
        }

        .indicator {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;

            .tick {
                display: block;
                height: 3px;
                width: 10px;
                border-radius: 17px;
                background: linear-gradient(180deg, #d7a417 0%, #fff4a0 58.79%, #f6de2c 100%);
                border-radius: 4px;
                flex: 0 0 auto;
            }

            &.short .tick {
                width: 5px;
            }

            .text {
                font-size: 8px;
                font-weight: 500;
                flex-grow: 1;
            }
        }
    }

    .top {
        position: relative;
        overflow: hidden;
        .flow {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 96px;
            overflow: hidden;
            transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

            svg {
                transition: 0.1s ease-in-out;
            }
        }
    }

    .bottom {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 36px;
        overflow: hidden;
    }
}

.position {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
}

.text {
    display: flex;
    align-items: center;
    gap: 8px;
}
