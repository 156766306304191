@import "../../../styles/style.scss";

$backgroundColor: #e1f2ff;
$transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);

.editPhoto {
    background-color: $backgroundColor;
    // height: -webkit-fill-available;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    aspect-ratio: 343/442;
}

.dimensions {
    height: 100%;
    min-height: 442px;
    max-height: 603px;
    max-width: 468px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 600px) {
        max-height: 442px;
        max-width: 343px;
    }
}

.imageContainer {
    width: 100%;
    position: relative;
    height: 100%;
    aspect-ratio: 343/442;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    z-index: 10;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.3s ease-out;
    background-color: transparent;

    p {
        margin-top: 8px;
    }

    &.active {
        background-color: $backgroundColor;
        opacity: 1;
    }

    [class*="lds-grid"] {
        position: relative;
    }

    &.shareLoaded {
        opacity: 1;
    }
}

.errorImage {
    text-align: center;
    position: absolute;
    font-weight: 600;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    color: $primary;
}

.optionsContainer {
    margin: auto 0 0;
    position: relative;
    z-index: 11;
    background: $backgroundColor;
    padding: 0 16px;

    > div:last-child {
        margin-bottom: 16px;
    }
}

.filterOptions,
.photoOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    margin: 16px 0;
    box-shadow:
        -14px -14px 36px #ffffff,
        14px 14px 36px rgba(0, 0, 0, 0.22),
        inset -18px -18px 16px #ffffff,
        inset 18px 18px 20px rgba(13, 39, 80, 0.18);
    background-color: $backgroundColor;
    height: 140px;
    gap: 16px;
}

.photoOptions {
    overflow-x: auto;
    overflow-y: hidden;

    .overflow {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 16px 0;
        margin: -16px 0;
        min-width: 1px;
    }

    .option {
        all: unset;
        position: relative;
        height: 100px;
        border-radius: 8px;
        background: transparent;
        display: grid;
        place-content: center;
        padding: 4px;
        transition: 2s ease-in;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }

        &:hover,
        &.active {
            .activeIcon,
            .filterBackground {
                opacity: 1;
            }
        }

        svg {
            position: relative;
            transform: scale(1.3);
        }

        .activeIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background-color: $backgroundColor;
            box-shadow:
                inset 5px 5px 6px rgba(0, 0, 0, 0.11),
                inset -7px -7px 5px #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition;
            opacity: 0;
            pointer-events: none;
        }

        .filterBackground {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $transition;
            background: rgb(15, 0, 255);
            background: linear-gradient(-45deg, rgba(15, 0, 255, 1) 0%, rgba(255, 0, 51, 1) 100%);
            box-shadow:
                -8px -8px 14px #ffffff,
                6px 7px 16px rgba(13, 39, 80, 0.41);
            opacity: 0;

            div {
                border-radius: 4px;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                background-color: $backgroundColor;
                box-shadow:
                    inset 5px 5px 6px rgba(0, 0, 0, 0.11),
                    inset -7px -7px 5px #ffffff;
            }
        }
    }
}

.filterOptions {
    height: 72px;
    width: 150px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 1.5em;

    .option {
        all: unset;
        cursor: pointer;
        position: relative;
        transition: $transition;

        &:disabled {
            cursor: not-allowed;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgb(15, 0, 255);
            background: linear-gradient(-45deg, rgba(15, 0, 255, 1) 0%, rgba(255, 0, 51, 1) 100%);
            border-radius: inherit;
            opacity: 0;
            transition: $transition;
        }

        svg {
            position: relative;
            transition: $transition;
            transform: scale(0.85) translateY(3px);
        }

        &.notActive {
            svg {
                filter: grayscale(1);
            }

            .filterBackground {
                opacity: 0;
            }
        }

        &:hover {
            svg {
                filter: grayscale(0);
            }

            .filterBackground {
                opacity: 1 !important;
            }
        }

        .filterBackground {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: calc(100% + 1em);
            height: calc(100% + 1em);
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $transition;
            background: rgb(15, 0, 255);
            background: linear-gradient(-45deg, rgba(15, 0, 255, 1) 0%, rgba(255, 0, 51, 1) 100%);
            box-shadow:
                inset 0px -3px 9px -1px rgba(255, 255, 255, 0.5),
                inset 10px 2px 14px rgba(13, 39, 80, 0.18),
                inset -10px 2px 14px #ffffff;
            opacity: 1;

            div {
                border-radius: inherit;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                background-color: $backgroundColor;
                box-shadow:
                    -7px -6px 12px #ffffff,
                    4px 4px 4px rgba(0, 0, 0, 0.25),
                    inset 5px 5px 6px rgba(0, 0, 0, 0.11),
                    inset -7px -7px 5px #ffffff;
            }
        }
    }
}

.optionOverlay {
    width: 72px;
    position: relative;
}

.gridWrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 100%;
    max-height: 603px;
}

.modalOptions {
    margin-top: 12px;
    position: relative;
    z-index: 4;
    display: flex;
    gap: 8px;
    
    button {
        width: 100%;
    }
}