@import "./grid-animation.module.scss";
@import "./grid-variables";

.grid {
    margin: 0 auto;
    display: grid;
    // for visual purposes logic is in GridLarge component
    grid-template-areas:
        ". . . . ."
        ". m m m ."
        ". m m m ."
        ". m m m ."
        ". . . . .";
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    height: 100%;
    background-color: $gridBackground;

    .image-container {
        width: 100%;
        border: $gridBorderWidth solid $gridBorderColor;
        overflow: hidden;
        border-radius: $imageRadius;
        height: 100%;

        .whiteBorder {
            border: 2px solid white;
            border-radius: 6px;
            overflow: hidden;
            height: 100%;
        }
    }

    .image {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
