@import "../../../../../styles/style.scss";

.content {
    color: $dark;
    font-weight: 600;
    margin-top: 60px;
    padding: 0 1em;

    .scroll {
        padding: 150px 4em 4em;
        margin: -4em;

        /* hide scrollbar but allow scrolling */
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }
}
