// animation by https://codepen.io/anthonymarkreynolds/pen/ajKdOR

//--------------------
// Settings
//--------------------
$gridSize: 32;
$waveFreq: 0.2; //wave speed(Hz)
$amplitude: 4; //wave height
$offset: 1; //offsets animation timing positions(numbered on tiles)
$mosaic: 5; //Set between 0 - 10; REAL COOL EFFECT!
$colors: (
  primary: #522ca9,
  secondary: #6c52aa,
  tertiary: #8577a5,
  highlight: #c8b5f5,
  bright: #cbbfe7,
);

//--------------------
// Constants
//--------------------
$tileCount: ($gridSize * $gridSize);
$tileCountActual: 1024; //actual tiles(<div>'s) generated by pug
$tileSize: 32em / $gridSize;
$frequency: 1 / $waveFreq; //Hz to s
$period: $waveFreq;
$relativeAmp: $tileSize * $amplitude;
$pixelate: (-$mosaic / 10) + 1.3;

//--------------------
// Wave Generator
//--------------------
@mixin waveGenerator {
  animation-duration: #{($frequency)}s;
  animation-name: wave;
  animation-iteration-count: infinite;
  animation-delay: 5s;

  @for $i from 1 through $tileCountActual {
    $position: (($i) - (floor($i / ($gridSize - $offset))) * ($gridSize - $offset));
    $delay: ($position - $gridSize) / ($period * ($gridSize));
    &:nth-child(#{$i}) {
      &:after {
        content: "#{$position}";
      }
      animation-delay: #{$delay}s;
      z-index: $tileCount - $i; //gives closer tiles a higher z-index
      @if $i > $tileCount {
        display: none; //prevents additional tiles overflowing grid
      }
    }
  }
}

@mixin waveGeneratorLarge {
  animation-duration: #{($frequency)}s;
  animation-name: wave;
  animation-iteration-count: infinite;
  animation-delay: 5s;

  @for $i from 1 through $tileCountActual {
    $position: (($i) - (floor($i / ($gridSize - $offset))) * ($gridSize - $offset));
    $delay: ($position - 64) / ($period * (16));
    &:nth-child(#{$i}) {
      &:after {
        content: "#{$position}";
      }
      animation-delay: #{$delay}s;
      z-index: $tileCount - $i; //gives closer tiles a higher z-index
      @if $i > $tileCount {
        display: none; //prevents additional tiles overflowing grid
      }
    }
  }
}

//--------------------
// Grid Setup
//--------------------
.grid-thousand {
  height: calc(100vh - 300px);
  width: 100%;
  display: grid;
  grid-template-columns: repeat($gridSize, 1fr);
  grid-template-rows: repeat($gridSize, 1fr);
  grid-auto-flow: column dense;
  list-style: none;
  margin: auto;
  padding: 0;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border: 16px solid map-get($colors, primary);
    z-index: 1100;
  }
  

  @media screen and (min-width: 768px) {
    min-width: 1300px;
    height: calc(100vh - 200px);
    min-height: 730px;
  }

  &.large div {
    @include waveGeneratorLarge;
  }

  &.regular div {
    @include waveGenerator;
  }

  div {
    position: relative;
    display: block;
    background-color: map-get($colors, secondary);
    transition: all 10s ease;
    overflow: hidden;
    color: map-get($colors, tertiary);
    font-family: "Courier New", Courier;
    font-size: $tileSize/3;

    &:nth-child(odd) {
      background-color: map-get($colors, primary);
    }
    &:hover {
      background-color: map-get($colors, highlight);
    }
  }
}

//--------------------
// Wave Animation
//--------------------
@keyframes wave {
  0% {
    // box-shadow: 0 0 0, map-get($colors, tertiary);
    transform: scale($pixelate);
  }
  50% {
    // box-shadow: (-$relativeAmp) (-$relativeAmp) ($relativeAmp * 2) map-get($colors, tertiary);
    transform: translateY($relativeAmp) translateX($relativeAmp) scale(1.2);
  }
  100% {
    // box-shadow: 0 0 0 map-get($colors, tertiary);
    transform: scale($pixelate);
  }
}

.grid-thousand {
  &__image {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
