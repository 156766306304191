@import "../../../../styles/style.scss";

.content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    justify-content: center;
    background-color: #e1f2ff;
    min-width: 210px;
    border-radius: 14px;
    width: 100%;
    box-shadow: -14px -14px 36px #ffffff, 14px 14px 36px rgba(0, 0, 0, 0.22), inset -10px -10px 16px #ffffff,
        inset 10px 10px 20px rgba(13, 39, 80, 0.18);

    .title {
        font-weight: 500;
    }

    .counter {
        display: flex;
        gap: 0.25em;

        .paused,
        .numberContainer {            
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 48px;

            .number {
                font-weight: 500;
                color: $counterTimerColor;
                font-size: 1.125rem;
            }

            .text {                
                color: #090003;
                font-weight: 400;
                font-size: .875rem;
            }
        }
    }
}
