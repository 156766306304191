.bounce {
    animation-name: random;
    animation-fill-mode: forwards;
    animation-delay: var(--value-d);
    animation-duration: 2s;
    -webkit-animation-name: random;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-delay: var(--value-d);
    -webkit-animation-duration: 2s;
    opacity: 0;
}

@keyframes random {
    0% {
        transform: translate(0, 0) rotateY(0deg) rotate(0deg);
        opacity: 0;
    }

    5% {
        transform: translate(0, 0) rotateY(0deg) rotate(0deg);
        opacity: 0;
    }

    6% {
        opacity: 0;
    }

    10% {
        transform: translate(var(--value-x), var(--value-y)) rotateY(var(--value-r)) rotate(var(--value-r));
        opacity: 1;
    }

    40% {
        transform: scale(1) translateY(0, 0);
        filter: brightness(1);
    }

    45% {
        transform: scale(1.2, 0.6);
    }

    55% {
        transform: scale(0.8, 1.1) translateY(-50px);
        filter: brightness(8);
    }

    65% {
        transform: scale(1) translateY(0);
        filter: brightness(1);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0) rotateY(0deg) rotate(0deg);
    }
}
