$inputBoxShadow: -18px -14px 24px #ffffff, 12px 12px 24px rgba(0, 0, 0, 0.08), inset -14px -14px 13px #ffffff,
    inset 14px 14px 20px rgba(13, 39, 80, 0.18);

.input {
    background: #e1f2ff;
    border-radius: 90px;
    box-shadow: $inputBoxShadow;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 24px;
    position: relative;

    input {
        background-color: transparent;
    }

    [class*="PhoneInput--focus"] + span {
        border-color: #522ca9;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 90px;
        border-color: transparent;
        border-width: 2px;
        border-style: solid;
        pointer-events: none;
    }
}
