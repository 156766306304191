@import "../../../../styles/style.scss";

.privacy {
  @include modal;
  z-index: 25;

  .container {
    position: relative;
    background-color: $white;
    height: 523px;
    border-radius: 12px;
    overflow-y: hidden;
    // Temporary fix
    width: 100%;
    max-width: 500px;
  }

  .title {
    margin: 2em 0 1.5em;
    text-align: center;
  }

  .content {
    padding: 16px 16px 80px;
    font-size: 0.9375rem;
    color: #4f505d;
    line-height: 24px;
    font-weight: 500;
    height: 375px;
    overflow-y: scroll;

    ul {
      padding-left: 24px;
      li {
        list-style-type: disc;
      }
    }
  }

  .options {
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    width: 100%;
    padding: 1em;
    background-color: white;
    border-top: 1px solid #d6d6da;
    z-index: 20;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .reject,
  .accept {
    @include button;
    border: none;
    width: 100%;
    color: $dark;
  }

  .reject {
    margin-right: 0.75em;
    background-color: #f3be34;
  }

  .accept {
    background-color: $primary;
    color: $white;
  }

  .error {
    position: relative;
    color: crimson;
    text-align: center;
    padding: 1em;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
  }

  .tab {
    @include button;
    border: none;
    padding: 0.5em 2em;
    margin: 1em auto;
  }

  .close {
    position: absolute;
    top: 1em;
    right: 1em;

    svg {
      font-size: 1.5rem;
    }
  }
}
