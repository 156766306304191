button.primary {
    box-shadow: inset 0px 0px 28px #16004a;
    background-color: #5003fb;
    color: #fff;

    &:hover {
        background-color: #4802e1;
        box-shadow: inset 0px 0px 28px #16004a;
    }
}
