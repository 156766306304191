.close {
    position: absolute;
    right: 12px;
    top: 12px;
    transform: scale(0.75);
    z-index: 20;

    button {
        min-width: 36px;
        background: #edf1f4;
        border-radius: 50%;
        width: 36px !important;
        height: 36px !important;
        box-shadow: -6px -5px 12px #ffffff, 5px 5px 15px rgba(0, 0, 0, 0.18), inset -4px -4px 12px #ffffff,
            inset 4px 4px 10px rgba(13, 39, 80, 0.18) !important;
        font-size: 1.25rem;
    }
}