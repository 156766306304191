.next {
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.shareButton {
    border-radius: 50px;
    padding: 0.5em 1em;
    background: #edf1f4;
    box-shadow: -6px -5px 12px #ffffff, 5px 5px 15px rgba(0, 0, 0, 0.18), inset -4px -4px 12px #ffffff,
        inset 4px 4px 10px rgba(13, 39, 80, 0.18);

    &.disabled {
        opacity: 0.5;
    }
}