.title {
    text-align: center;
    padding-bottom: 0;
    
    h2 {
        font-weight: 700;
    }
}

.subtitle {
    margin-bottom: 16px !important;
}

.content {
    text-align: center;

    form {
        gap: 16px;
    }
}
