@import "./grid-animation.module.scss";
@import "./grid-variables";

.grid {
    margin: 0 auto;
    display: grid;
    // for visual purposes logic is in GridLarge component
    grid-template-areas:
        ". . . . . . . . . ."
        ". . . . . . . . . ."
        ". . m m m m m m . ."
        ". . m m m m m m . ."
        ". . m m m m m m . ."
        ". . m m m m m m . ."
        ". . m m m m m m . ."
        ". . m m m m m m . ."
        ". . . . . . . . . ."
        ". . . . . . . . . .";
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    height: 100%;
    background-color: $gridBackground;

    .image-container {
        width: 100%;
        border: $gridBorderWidth solid $gridBorderColor;
        overflow: hidden;
        border-radius: $imageRadius;        
        height: 100%;

        &.bordered {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                background: $gradientBackground;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 8px;
            }

            padding: $gradientBorderWidth;

            .extraBorder {
                position: relative;
                background: $gridBorderColor;
                padding: $gridInnerBorderWidth;
                height: 100%;
                width: 100%;
                border-radius: $gridInnerBorderRadius;                

                img {
                    border-radius: $gridInnerBorderRadius;
                    border: none;
                }
            }

            img {
                overflow: hidden;
                border-radius: $gridInnerBorderRadius;
                border: $gridInnerBorderWidth solid $gridBorderColor;
                position: relative;
            }
        }
        
        .whiteBorder {                
            border: 2px solid white;
            border-radius: 6px;
            overflow: hidden;
            height: 100%;
        }
    }

    .image {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
