@mixin button {
  border-radius: 10px;
  padding: 1em 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  display: block;
  text-transform: capitalize;
  // letter spacing for font if not changed
  letter-spacing: .5px;
  cursor: pointer;
  outline: none;
}

@mixin modal {
  position: fixed;
  background-color: rgba(18, 21, 61, 0.8);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  z-index: 20;
  padding: 0 1em;
}

@mixin alert {
  position: fixed;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: $primary;
  width: 100%;
  max-width: 500px;
  left: 50%;
  border-radius: 20px 20px 0 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: .3s ease-in-out;
  transform: translate(-50%, 100%);
  color: $white;

  &.show {
    transform: translate(-50%, 0);
    max-height: 100%;
    padding: 1em;
  }
}

@mixin numberInput {
  @include button;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  padding: .5em 1em;

  >input {
    font-size: 1rem;
    background-color: transparent;
    font-weight: 700;
    color: $white;
    border-left: 1px solid $lightGrey;
    padding-left: 1em;
    margin-left: 1em;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    height: 37px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  > svg {
    font-size: 2rem;
    fill: $lightGrey;
  }

}