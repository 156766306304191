$primary: #522ca9;
$dark: #12153d;
$grey: #8683a7;
$lightGrey: #bbbac9; //need exact color
$mediumGrey: #5b5b5b;
$white: #fff;
$black: #000;
$yellow: #f3be34;

$loadIconColor: #522ca9;

$mainBackground: #fff;
$primaryBackground: #ecf7ff;
$primaryShadow: -16px -14px 24px #ffffff, 14px 14px 24px rgba(0, 0, 0, 0.12), inset -10px -10px 13px #ffffff,
    inset 18px 18px 20px rgba(13, 39, 80, 0.18);

// Grids
$gradientBackground: linear-gradient(135deg, #ff0033 0%, #0f00ff 100%);

$counterTimerColor: $primary;