@import "../../../styles/style.scss";

.photo {
    position: relative;
    // maybe better solution WebComponent as whitespace bottom
    height: 100vw;
    height: -webkit-fill-available; // this is new
    overflow-y: hidden;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 8;
    }
}
