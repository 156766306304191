@import "../../../../../../styles/variables";

.accordion {
    background: $primaryBackground !important;

    &[class*="MuiAccordion-root"][class*="Mui-expanded"] {
        margin: 0;
    }

    &[class*="MuiAccordion-root"][class*="Mui-expanded"]:last-child {
        margin-bottom: 16px;
    }

    &:last-child {
        margin-bottom: 16px;
    }
}
