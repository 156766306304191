.share {
  padding: 4em .75em .7em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__image {
    width: 100%;
  }

  &__share,
  &__save,
  &__donate {
    @include button;
    border: none;
    width: 100%;
    color: $white;
    background-color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      font-size: 1.4rem;
      margin-right: .4em;
    }

  }

  &__save {
    margin-left: .75em;
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;

    & > svg {
      font-size: 1.5rem;
    }

    + button {
      margin-left: .75em;
    }
   
  }
  &__content {
    display: flex;
  }

  &__buttons {
    margin-top: 1em;
   
  }

  &__donate {
    margin-top: .75em;
    background-color: $primary;
    width: 100%;
  }

  &__socials {
    padding: 0 1em;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-color: $primary;
    width: 100%;
    border-radius: 20px 20px 0 0;
    max-height: 0px;
    overflow-y: hidden;
    transition: .3s ease-in-out;
    transform: translateY(100%);
    color: $white;
    
    &.show {
      transform: translateY(0);
      max-height: 100%;
      padding: 1em;
    }

    & > span {
      margin: 0 1em;
      text-align: center;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: $white;
    margin: 0 .25em;
  }
}