@import "../../../../styles/style.scss";

.card {
    padding: 18px;
    border-radius: 14px;
    background: #ecf7ff;
    box-shadow: 0px 8px 8px rgba(59, 23, 173, 0.7), -24px -24px 22px #ffffff, 24px 24px 26px rgba(13, 39, 80, 0.02),
        inset -24px -24px 44px #ffffff, inset 44px 44px 44px rgba(13, 39, 80, 0.08);
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .image {
        border-radius: 8px;
        overflow: hidden;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .counter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        .verticalButton {
            padding: 16px 11px;
            width: 124px;
            max-width: 100%;
            border-radius: 54px;
            text-wrap: wrap;
        }

        .content {
            text-align: center;
        }
    }

    .title {
        text-align: center;
        color: $primary;

        > * {
            font-weight: 500;
        }
    }
}
