@import "styles/style.scss";

.content {
    @include modal;

    .box {
        background-color: $white;
        border-radius: 12px;
        padding: 1.5em;
    }

    .text {
        margin-top: 0;
        text-align: center;
        font-weight: 700;
        color: $dark;
        line-height: 1.5em;
    }

    &__dismiss {
        @include button;
        width: 100%;
        color: $dark;
        border: 1px solid $dark;
        background: $white;
    }
}
