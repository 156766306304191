.banner {
    text-align: center;
    position: relative;
    isolation: isolate;
    width: fit-content;
    margin: 0 auto;

    > h6 {
        color: #fff;
        transform: translateY(-3px);
    }

    .left,
    .middle,
    .right {
        z-index: -3;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(1.5);

        svg {
            height: 100%;
            width: 140px;
        }
    }

    .left {
        left: -10px;
        overflow: hidden;
        width: 50px;
    }

    .right {
        right: -10px;
        overflow: hidden;
        width: 50px;

        > svg {
            transform: translateX(-90px);
        }
    }

    .middle {
        z-index: -4;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.5);
        width: 50%;
        overflow: hidden;

        > svg {
            transform: translateX(-10px);
        }
    }
}
