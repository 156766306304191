@import "../../../../../styles/variables";

$gradientBackground: linear-gradient(135deg, #6c63ff 0%, #ff6584 100%);

.layer,
.loading {
    position: absolute;
    height: auto;
    width: 20%;
}

.loading {
    top: 35%;
    left: 3.5em;
    top: unset;
    bottom: 1em;
    left: 6em;
}

.layer {
    bottom: 64px;
    left: 9em;
    padding: 2px;

    .background {
        padding: 2px;
        background: $gradientBackground;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
