@import "../../../styles/style.scss";
//TODO transfer css

$primary: #3b17ad;
$baseTextColor: #090003;

.container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 16px;
}

.content {
    background-color: $primaryBackground;
    box-shadow: 0px 8px 8px rgba(59, 23, 173, 0.7), -24px -24px 22px #ffffff, 24px 24px 26px rgba(13, 39, 80, 0.02),
        inset -24px -24px 44px #ffffff, inset 44px 44px 44px rgba(13, 39, 80, 0.08);
    padding: 30px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .title {
        color: $primary;
        font-size: 1.25rem;
        font-weight: 500;
    }

    .image {
        position: relative;
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 12px 12px 26px rgba(0, 0, 0, 0.18), -12px -12px 15px #ffffff;
        background-color: #6bb7ff;
    }
}

.information {
    margin-bottom: 2em;

    h6 {
        color: $primary;
    }

    p {
        font-size: 0.875rem;
        color: $baseTextColor;
    }
}

.modal {
    [class*=MuiDialogContent-root] {
        overflow: unset;
    }
}