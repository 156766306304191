@import "../../../../styles/style.scss";

.navigation {
    width: 100%;
    max-width: 700px;
    margin: auto;
    padding: 20px 10px;
    position: fixed;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;

    ul {
        margin: 0 auto;
        max-width: 500px;
        display: flex;
        gap: 16px;
        justify-content: center;
    }

    button {
        min-width: 0 !important;
        border-radius: unset;
        padding: 14px 12px;
        border-radius: 90px;
        white-space: nowrap;
    }
}
