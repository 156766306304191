@import "../../../../styles/style.scss";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 15;
    letter-spacing: 0.5px;
    font-weight: 700;
    height: 3.75em;
    max-width: 700px;

    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        background-color: inherit;
        height: 3.75em;
        width: 100vw;
        z-index: -1;
    }

    & svg {
        cursor: pointer;
    }


    &.mobile {
        max-width: unset;
        left: 0;
    }

    .logo {
        border: none;
        background-color: transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .nav {
        position: relative;
        height: 2.5px;
        width: 12px;
        background-color: $dark;
        border-radius: 10px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            background-color: $dark;
            width: calc(100% + 6px);
            height: 2.5px;
            border-radius: inherit;
        }

        &::before {
            transform: translateY(-0.375em);
        }

        &::after {
            transform: translateY(0.375em);
        }
    }

    & svg:first-of-type {
        font-size: 1.5em;
    }

    .camera {
        display: flex;
        align-items: center;
        position: relative;

        & > svg {
            font-size: 1.5rem;
            fill: $dark;

            &:first-of-type {
                position: absolute;
                right: 1.5em;
            }
        }
    }

    .back {
        background-color: #edf1f4;
        box-shadow: -6px -5px 12px #ffffff, 5px 5px 15px rgba(0, 0, 0, 0.18), inset -4px -4px 12px #ffffff,
            inset 4px 4px 10px rgba(13, 39, 80, 0.18);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 90px;
        height: 36px;
        min-width: 36px;
        padding: 0 6px;
        font-size: 0.75rem;
        color: #3b17ad;

        & > span {
            font-size: 0.75rem;
            margin-left: 0.2em;
            color: inherit;
        }
    }

    .finish {
        cursor: pointer;
        font-size: 1.125rem;
        color: $primary;
    }

    .next {
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .shareButton {
        border-radius: 50px;
        padding: 0.5em 1em;
        background: #edf1f4;
        box-shadow: -6px -5px 12px #ffffff, 5px 5px 15px rgba(0, 0, 0, 0.18), inset -4px -4px 12px #ffffff,
            inset 4px 4px 10px rgba(13, 39, 80, 0.18);

        &.disabled {
            opacity: 0.5;
        }
    }
}
