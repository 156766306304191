$inputBoxShadow: -18px -14px 24px #ffffff, 12px 12px 24px rgba(0, 0, 0, 0.08), inset -14px -14px 13px #ffffff,
    inset 14px 14px 20px rgba(13, 39, 80, 0.18);

.input {
    [class*="MuiInputBase-root"] {
        background: #E1F2FF;
        border-radius: 90px;
        box-shadow: $inputBoxShadow;
        min-height: 48px;

        input {
            border: unset;
        }

        fieldset {
            border-width: 0;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}