@import "styles/style.scss";

.container {
    [class*="MuiPaper-root"] {
        background-color: transparent;
        min-height: 450px;
        border-radius: 20px;
    }

    .content {
        position: relative;
        background: linear-gradient(135deg, #ff0033 0%, #0f00ff 100%);
        isolation: isolate;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        gap: 24px;
        
        &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 8px;
            width: calc(100% - 16px);
            height: calc(100% - 16px);
            background-color: $primaryBackground;
            z-index: -1;
            border-radius: 18px;
            box-shadow: 14px 14px 36px rgba(0, 0, 0, 0.22), inset -30px -30px 16px #FFFFFF, inset 30px 30px 39px rgba(13, 39, 80, 0.18);
        }
    }

    .total {
        padding: 12px 0;
        background-color: $primaryBackground;
        font-weight: 600;
        width: 100%;
        text-align: center;
        font-size: 28px;
        box-shadow: -18px -14px 24px #ffffff, 12px 12px 24px rgba(0, 0, 0, 0.08), inset -14px -14px 13px #ffffff,
            inset 14px 14px 20px rgba(13, 39, 80, 0.18);
        border-radius: 90px;
        margin: 20px 0 40px;
    }

    .imageContainer {
        position: relative;

        .image {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;

            &.position {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .title {
        text-align: center;
        font-weight: 700;
        font-size: 1.125rem;
    }

    .close {
        position: absolute;
        right: 12px;
        top: 12px;
        transform: scale(0.75);
        z-index: 20;

        button {
            min-width: 36px;
            background: #edf1f4;
            border-radius: 50%;
            width: 36px !important;
            height: 36px !important;
            box-shadow: -6px -5px 12px #ffffff, 5px 5px 15px rgba(0, 0, 0, 0.18), inset -4px -4px 12px #ffffff,
                inset 4px 4px 10px rgba(13, 39, 80, 0.18) !important;
            font-size: 1.25rem;
        }
    }
}
