@import "../../../../styles/style.scss";

.description {
    padding: 18px;
    background-color: #6bb7ff;
    border-radius: 8px;
    box-shadow: 12px 12px 26px rgba(0, 0, 0, 0.18), -12px -12px 15px #ffffff;
    line-height: 22px;
}

.modal {
    [class*="MuiPaper-elevation24"] {
        box-shadow: 0px 0px 60px #3b17ad, 24px 24px 26px rgba(13, 39, 80, 0.02), inset -24px -24px 44px #ffffff,
            inset 44px 44px 44px rgba(13, 39, 80, 0.08);
    }
}

.charities {
    .radio {
        > span[class*="MuiFormControlLabel-label"] {
            width: 100%;
        }
    }

    [class*="MuiRadio-colorSecondary"] {
        &:hover {
            background-color: rgba(15, 0, 255, 0.04) !important;
        }
    }

    [class*="MuiRadio-colorSecondary"][class*="Mui-checked"] {
        color: rgb(15, 0, 255);
        position: relative;

        &:hover {
            background-color: rgba(0, 150, 0, 0.04);
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }

        &:before {
            background: rgb(15, 0, 255);
            background: linear-gradient(135deg, #ff0033 0%, #0f00ff 100%);
            z-index: 1;
        }

        &:after {
            border: 3px solid #e1f2ff;
            width: 16px;
            height: 16px;
            z-index: 2;
        }
    }
}
